<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="客户" prop="client_id">
                <el-select
                    v-model="form.client_id"
                    filterable
                    placeholder="选择客户"
                    class="clientc"
                    clearable
                >
                    <el-option
                        v-for="client in fAllClient"
                        :key="client.id"
                        :label="client.name"
                        :value="client.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="产品名称" prop="product_name">
                <el-input
                    v-model="form.product_name"
                    placeholder="品名搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="namec"
                ></el-input>
            </el-form-item>
            <el-form-item label="订单号" prop="order_num">
                <el-input
                    v-model="form.order_num"
                    placeholder="订单号搜索"
                    @keyup.enter.native="search()"
                    clearable
                    class="namec"
                ></el-input>
            </el-form-item>
            <el-form-item label="对账状态" prop="state">
                <el-select
                    v-model="form.state"
                    filterable
                    placeholder="选择对账状态"
                    class="statec"
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未对账','对账中','已对账']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择日期" prop="date">
                <el-date-picker
                    v-model="form.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button type="info" plain @click="output" v-if="$_has(output_auth)">导出</el-button>
            </el-form-item>
        </el-form>
        <span style="font-size:14px;color: #AE0000;">温馨提示：为优化此页面加载速度，请输入搜索条件后再进行搜索</span>
    </el-card>
</template>

<script>
export default {
    name: 'StorageMoneyBillSearchBarComponent',
    data() {
        return {
            form: {
                client_id: '',
                product_name: '',
                order_num: '',
                state: '',
                date: '',
                page: 1,
            },
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        output() {
            this.$emit('output', this.form)
        }
    },
    filters: {},
    props: {
        fAllClient: Array,
        output_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>