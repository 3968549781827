<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="企业名称" prop="enterprise_name">
                <el-input
                    v-model="form.enterprise_name"
                    placeholder="企业名称搜索"
                    class="enterprise_namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="审批状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable

                >
                    <el-option
                        v-for="(v,i) in this.$store.state.state"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作状态" prop="status">
                <el-select
                    v-model="form.status"
                    placeholder="选择状态"
                    class="statusc"
                    clearable
                >
                    <el-option
                        label="禁用"
                        :value=0
                    ></el-option>
                    <el-option
                        label="临时"
                        :value=1
                    ></el-option>
                    <el-option
                        label="长期"
                        :value=2
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合同状态" prop="contract_state">
                <el-select
                    v-model="form.contract_state"
                    placeholder="选择状态"
                    class="contract_state"
                    clearable
                >
                    <el-option
                        label="不签"
                        :value=0
                    ></el-option>
                    <el-option
                        label="待签"
                        :value=1
                    ></el-option>
                    <el-option
                        label="已签"
                        :value=2
                    ></el-option>
                    <el-option
                        label="过期"
                        :value=3
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="企业类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择企业类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllType"
                        :key="v"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="账期类型" prop="account_period_type">
                <el-select
                    v-model="form.account_period_type"
                    placeholder="选择账期类型"
                    class="account_period_typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in AllAccountPeriodType"
                        :key="v"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="督办人" prop="supervisor">
                <el-select
                    v-model="form.supervisor"
                    placeholder="选择督办人"
                    class="supervisorc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <!-- <el-checkbox v-model="form.daoqi" label="到期" border style="margin-right: 10px;"><el-badge :value="daoqi_num" :max=999>到期未签</el-badge></el-checkbox> -->
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button plain round @click="out_excel" v-if="$_has('导出合作企业')">导出</el-button>
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加合作企业申请</el-button
            >
        </el-form>
    </el-card>
</template>

<script>

export default {
    name: 'CooperativeEnterpriseSearchBarComponent',
    data() {
        return {
            form: {
                name: '',
                state: '',
                status: '',
                contract_state: '',
                is_cooperation: '',
                enterprise_name: '',
                type: '',
                supervisor: '',
                account_period_type: '',
                daoqi: false,
                page: 1,
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        daoqi_btn() {
            this.$emit('daoqi')
        },
        out_excel() {
            this.$emit('out_excel', this.form)
        }
    },
    filters: {},
    props: {
        add_auth: {
            type: String,
            default() {
                return ''
            }
        },
        AllType: {
            type: Array,
            default() {
                return []
            }
        },
        AllAccountPeriodType: {
            default() {
                return []
            }
        },
        AllStaff: {
            default() {
                return []
            }
        },
        daoqi_num:{
            type: String|Number,
            default() {
                return ''
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>